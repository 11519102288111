import React from 'react';

const ICSContent = () => {
  return (
    <div className="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div className="line-height-3 text-lg text-700 mb-4 animate__animated animate__bounceInUp animate__delay-1s">
        Integrated Community Supports (ICS) is a program offered by the
        Department of Human Services (DHS) that caters to adults aged 18 and
        older residing in a provider-controlled ICS setting. Our ICS services
        encompass a wide range of supportive measures, including community
        participation, health safety and wellness, household management, and
        adaptive skills.
      </div>
      <div className="line-height-3 text-lg text-700 mb-4 animate__animated animate__bounceInUp animate__delay-1s">
        With ICS, we prioritize training and support tailored to meet each
        person's individualized needs and goals. Our approach ensures that all
        tools and metrics employed are person-centered, recognizing the
        importance of the individual's preferences and choices.
      </div>
      <div className="line-height-3 text-lg text-700 mb-4 animate__animated animate__bounceInUp animate__delay-1s">
        We strongly believe in empowering individuals to take charge of their
        own care and services. Through ICS, we place great emphasis on the power
        of the individual to make decisions and guide the services they wish to
        receive. Our commitment lies in enabling individuals to shape their own
        journey, ensuring their voices are heard, and providing the necessary
        support to help them achieve their desired outcomes.
      </div>
      <div
        className="border-blue-500 font-medium text-2xl p-3 mb-3 text-900 animate__animated animate__bounceInUp animate__delay-1s"
        style={{ borderLeft: '8px solid' }}
      >
        Community Participation
      </div>

      <ul className="mb-4 text-lg ml-6 p-0 animate__animated animate__bounceInUp animate__delay-1s">
        <li className="mb-3">
          Community mobility and pedestrian safety (e.g., safely getting in and
          around the community).
        </li>
        <li className="mb-3">Community resource use and access.</li>
        <li className="mb-3">Community safety and awareness. </li>
        <li className="mb-3">
          Informal support system and network development.{' '}
        </li>
        <li className="mb-3">Interpersonal communications skills. </li>
        <li className="mb-3">
          Leisure, recreation and socialization planning.{' '}
        </li>
      </ul>
      <div
        className="border-blue-500 font-medium text-2xl p-3 mb-3 text-900 animate__animated animate__bounceInUp animate__delay-1s"
        style={{ borderLeft: '8px solid' }}
      >
        Health, Safety And Wellness
      </div>

      <ul className="mb-4 text-lg ml-6 p-0 animate__animated animate__bounceInUp animate__delay-1s">
        <li className="mb-3">
          Collaboration with the person to arrange health care (e.g., physical,
          mental, chemical), meaningful activities, social services, meetings
          and appointments
        </li>
        <li className="mb-3">
          Cueing, guidance, supervision, training or instructional support to
          complete self-care activities (Note: Cannot duplicate use of eligible
          Medical Assistance state plan home care services; see CBSM – Home care
          overview).
        </li>
        <li className="mb-3">Health services support. </li>
        <li className="mb-3">
          Help for the person to activate and build resiliency factors.{' '}
        </li>
        <li className="mb-3">
          Support for the person to design and meet individualized strategies to
          reach their health, safety and wellness goals.{' '}
        </li>
      </ul>
      <div
        className="border-blue-500 font-medium text-2xl p-3 mb-3 text-900 animate__animated animate__bounceInUp animate__delay-1s"
        style={{ borderLeft: '8px solid' }}
      >
        Adaptive Skills
      </div>

      <ul className="mb-4 text-lg ml-6 p-0 animate__animated animate__bounceInUp animate__delay-1s">
        <li className="mb-3">Crisis prevention skills.</li>
        <li className="mb-3">
          Implementation of positive support strategies.{' '}
        </li>
        <li className="mb-3">
          Sensory/motor development involved in acquiring functional skills.
        </li>
        <li className="mb-3">Support strategies for self-sufficiency.</li>
        <li className="mb-3">
          Support and training to increase positive behavior, resulting in
          reduction or elimination of challenging behavior.
        </li>
      </ul>
      <div
        className="border-blue-500 font-medium text-2xl p-3 mb-3 text-900 animate__animated animate__bounceInUp animate__delay-1s"
        style={{ borderLeft: '8px solid' }}
      >
        Household Management
      </div>

      <ul className="mb-4 text-lg ml-6 p-0 animate__animated animate__bounceInUp animate__delay-1s">
        <li className="mb-3">Cueing, guidance, supervision, training or instructional support to complete routine household care and maintenance.</li>
        <li className="mb-3">Household safety knowledge and skills. </li>
        <li className="mb-3">Tenancy support and advocacy.</li>
      </ul>
    </div>
  );
};

export default ICSContent;
