import React from 'react';
import Contact from '../components/contact/Contact';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ICSContent from '../components/services/service-page/ICSContent';
import PageHeader from '../components/services/service-page/PageHeader';

const ICSPage = () => {
  return (
    <Layout>
      <Seo title="ICS" />
      <PageHeader title="Intergrated Community Supports (ICS)" />
      <ICSContent />
      <Contact />
    </Layout>
  );
};

export default ICSPage;

export const Head = () => (
  <Seo title="ICS" />
);
